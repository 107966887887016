var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pa-9 pa-md-6 pa-lg-9"
  }, [_vm.$route.query.status ? _c('v-row', {
    staticClass: "mx-1"
  }, [_c('v-col', [_c('v-alert', {
    attrs: {
      "dense": "",
      "text": "",
      "type": _vm.$route.query.status
    }
  }, [_vm._v(" " + _vm._s(_vm.$route.query.message) + " ")])], 1)], 1) : _vm._e(), _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('h3', {
    staticClass: "page-title mb-7 font-weight-bold"
  }, [_vm._v("従業員一覧")]), _c('v-row', {
    staticClass: "no-gutters mb-6",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "キーワード検索",
      "dense": "",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.getDataFromApi
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }, [_c('v-btn', {
    staticClass: "white--text",
    attrs: {
      "slot": "append",
      "color": "blue",
      "small": "",
      "elevation": "0",
      "height": "23"
    },
    on: {
      "click": _vm.getDataFromApi
    },
    slot: "append"
  }, [_vm._v(" 検索 ")])], 1)], 1), _c('v-col', {
    staticClass: "text-right"
  }, [_c('v-btn', {
    staticClass: "btn-new",
    attrs: {
      "height": "30",
      "color": "#CFD3FE",
      "depressed": "",
      "min-width": "100",
      "to": "/management/employee/create"
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-plus ")]), _vm._v(" 従業員を登録する ")], 1)], 1)], 1), _c('Table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.initialLoad ? [] : _vm.users,
      "total-records": _vm.pagination ? _vm.pagination.records_total : null,
      "number-of-pages": _vm.pagination ? _vm.pagination.total_pages : null,
      "items-per-page": _vm.itemsPerPage,
      "footer": false,
      "loading": _vm.loading,
      "options": _vm.options
    },
    on: {
      "edit-button": function editButton($event) {
        return _vm.detail(_vm.item);
      },
      "click:row": _vm.detail,
      "update:options": function updateOptions($event) {
        _vm.options = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item.profile_image",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('img', {
          staticClass: "avatar",
          attrs: {
            "src": _vm.getUserPhoto(item)
          }
        })];
      }
    }, {
      key: "item.name",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('span', [_vm._v(_vm._s(item.last_name + ' ' + item.first_name))])];
      }
    }, {
      key: "item.contract_type",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('span', [_vm._v(_vm._s(_vm.contractArray[item.contract_type]))])];
      }
    }, {
      key: "item.employee_type",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('span', [_vm._v(_vm._s(_vm.employeeArray[item.employee_type]))])];
      }
    }, {
      key: "item.role",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('span', [_vm._v(_vm._s(_vm.roleArray[item.role]))])];
      }
    }, {
      key: "item.date_of_joining",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('span', [_vm._v(_vm._s(_vm._f("yearMonth")(item.date_of_joining)))])];
      }
    }, {
      key: "item.status",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('span', {
          class: item.status == 1 ? 'status-active' : 'status-not-active'
        }, [_vm._v(" " + _vm._s(item.status == 1 ? 'アクティブ' : '非アクティブ') + " ")])];
      }
    }, {
      key: "item.last_login_at",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('span', [_vm._v(_vm._s(_vm._f("yearMonthDateTime")(item.last_login_at)))])];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('v-btn', {
          attrs: {
            "color": "primary mr-2",
            "fab": "",
            "x-small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.detail(item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-pencil")])], 1)];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }