<template>
  <div class="pa-9 pa-md-6 pa-lg-9">
    <v-row v-if="$route.query.status" class="mx-1">
      <v-col>
        <v-alert dense text :type="$route.query.status">
          {{ $route.query.message }}
        </v-alert>
      </v-col>
    </v-row>
    <v-container fluid>
      <h3 class="page-title mb-7 font-weight-bold">従業員一覧</h3>
      <v-row class="no-gutters mb-6" align="center">
        <v-col cols="4">
          <v-text-field placeholder="キーワード検索" v-model="search" @input="getDataFromApi" dense outlined hide-details>
            <v-btn slot="append" color="blue" small @click="getDataFromApi" class="white--text" elevation="0"
              height="23">
              検索
            </v-btn>
          </v-text-field>
        </v-col>
        <v-col class="text-right">
          <v-btn height="30" class="btn-new" color="#CFD3FE" depressed min-width="100" to="/management/employee/create">
            <v-icon left>
              mdi-plus
            </v-icon>
            従業員を登録する
          </v-btn>
        </v-col>
      </v-row>
      <Table :headers="headers" :items="initialLoad ? [] : users"
        :total-records="pagination ? pagination.records_total : null"
        :number-of-pages="pagination ? pagination.total_pages : null" :items-per-page="itemsPerPage" :footer="false"
        @edit-button="detail(item)" @click:row="detail" :loading="loading" :options.sync="options">
        <template v-slot:item.profile_image="{ item }">
          <img class="avatar" :src="getUserPhoto(item)" />
        </template>
        <template v-slot:item.name="{ item }">
          <span>{{ item.last_name + ' ' + item.first_name }}</span>
        </template>
        <template v-slot:item.contract_type="{ item }">
          <span>{{ contractArray[item.contract_type] }}</span>
        </template>
        <template v-slot:item.employee_type="{ item }">
          <span>{{ employeeArray[item.employee_type] }}</span>
        </template>
        <template v-slot:item.role="{ item }">
          <span>{{ roleArray[item.role] }}</span>
        </template>
        <template v-slot:item.date_of_joining="{ item }">
          <span>{{ item.date_of_joining | yearMonth }}</span>
        </template>
        <template v-slot:item.status="{ item }">
          <span :class="item.status == 1 ? 'status-active' : 'status-not-active'">
            {{ item.status == 1 ? 'アクティブ' : '非アクティブ' }}
          </span>
        </template>
        <template v-slot:item.last_login_at="{ item }">
          <span>{{ item.last_login_at | yearMonthDateTime }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn color="primary mr-2" fab x-small @click="detail(item)">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <!--        <v-btn color="error" fab x-small>-->
          <!--          <v-icon small>mdi-delete</v-icon>-->
          <!--        </v-btn>-->
        </template>
      </Table>
    </v-container>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import dayjs from 'dayjs'
import Table from '@/components/admin/partials/Table/Table'
export default {
  name: 'List',
  components: { Table },
  data() {
    return {
      search: '',
      loading: true,
      itemsPerPage: 50,
      root: process.env.VUE_APP_BASEURL,
      headers: [
        { text: '表示順', align: 'center', value: 'display_order' },
        { text: '', align: 'center', value: 'profile_image', sortable: false },
        { text: 'お名前', align: 'center', value: 'name' },
        { text: 'ステータス', align: 'center', value: 'status' },
        { text: '雇用形態', align: 'center', value: 'contract_type' },
        { text: '従業員タイプ', align: 'center', value: 'employee_type' },
        { text: '管理権限', align: 'center', value: 'role' },
        { text: '入社日', align: 'center', value: 'date_of_joining' },
        { text: '最終ログイン', align: 'center', value: 'last_login_at' },
        { text: '', align: 'center', value: 'actions', sortable: false }
      ],
      roleArray: { SA: 'スーパーアドミン', A: 'アドミン', E: '従業員' },
      contractArray: {
        0: '短時間労働者',
        1: '正社員',
        2: '契約社員',
        3: '協業',
        4: '他'
      },
      employeeArray: { 0: '通常業務', 1: '日常清掃員', 2: '協業会社' },
      options: {},
      initialLoad: true
    }
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true
    }
  },
  computed: {
    users() {
      return this.$store.getters.allUsers
    },
    pagination() {
      return this.$store.getters.usersPagination
    }
  },
  created() {
    this.getDataFromApi()
  },
  methods: {
    getDataFromApi: debounce(function () {
      this.loading = true
      this.initialLoad = true
      let params = {
        search: this.search,
        paginate: this.options.itemsPerPage,
        page: this.options.page,
        sortBy: this.options.sortBy[0] ? this.options.sortBy[0] : 'display_order',
        sortDesc: this.options.sortDesc[0]
      }
      this.$store.dispatch('USER_GET_ALL', params).then(() => (
        this.initialLoad = false
      )).finally(() => (this.loading = false))
    }, 1000),
    // getDataFromApi() {
    //   this.loading = true
    //   // debounce(function() {
    //   let params = {
    //     search: this.search,
    //     paginate: this.options.itemsPerPage,
    //     page: this.options.page,
    //     sortBy: this.options.sortBy[0],
    //     sortDesc: this.options.sortDesc[0]
    //   }
    //   this.$store
    //     .dispatch('USER_GET_ALL', params)
    //     .then(() => (this.loading = false))
    //   // }, 500)
    // },
    detail(item) {
      this.$router.push({ path: '/management/employee/edit/' + item.id })
    },
    getUserPhoto(user) {
      return user.profile_image
        ? user.profile_image_url
        : `${this.root}images/userblank-rectangle.png`
    }
  },
  filters: {
    yearMonth(date) {
      if (!date) {
        return '-'
      }
      return dayjs(date).format('YYYY/MM')
    },
    yearMonthDateTime(date) {
      if (!date) {
        return '-'
      }
      return dayjs(date).format('YYYY/MM/DD HH:mm:ss')
    }
  }
}
</script>

<style src="./List.scss" lang="scss" scoped>
</style>
